
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiGameConfigDetail, apiGameConfigChange } from '@/api/shop'
@Component({
    components: {
    }
})
export default class UpdateGameConfig extends Vue {
  /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    // 添加商城表单数据
    form: any = {
        register_way: [], // 注册方式：1-手机号码注册（注册设置必传）
        login_way: [], //登录方式：1-账号密码登录；2-手机短信验证码登录
        is_mobile_register_code: 0, // 手机号码注册需验证码：0-关闭；1-开启
        coerce_mobile: 0, // 手机号码注册需验证码：0-关闭；1-开启
        is_register_invite_code: 0, // 手机号码注册需验证码：0-关闭；1-开启
        h5_wechat_auth: 0, // 微信公众号-微信授权登录：0-关闭；1-开启
        h5_auto_wechat_auth: 1, // 微信公众号-自动微信授权登录:0-关闭；1-开启;
        mnp_wechat_auth: 0, // 小程序-微信授权登录 :0-关闭；1-开启;
        mnp_auto_wechat_auth: 1, // 小程序-自动微信授权登录:0-关闭；1-开启;
        app_wechat_auth: 0, // app-微信授权登录:0-关闭；1-开启;
        app_alipay_auth: 0, // app-支付宝授权登录:0-关闭；1-开启;
        scene: '', // 场景：user-用户设置；register-注册设置；withdraw-提现设置
        toutiao_auth: 0,
        toutiao_auto_auth: 0
    };

    @Watch('form.h5_wechat_auth')
    h5WechatAuthWatch(val: any) {
        if (val == 0) {
            this.form.h5_auto_wechat_auth = 0
        }
    }
    @Watch('form.h5_auto_wechat_auth')
    h5AutoWechatAuthWatch(val: any) {
        if (val == 1) {
            this.form.h5_wechat_auth = 1
        }
    }
    @Watch('form.mnp_wechat_auth')
    mnpWechatAuthWatch(val: any) {
        if (val == 0) {
            this.form.mnp_auto_wechat_auth = 0
        }
    }
    @Watch('form.mnp_auto_wechat_auth')
    mnpAutoWechatAuthWatch(val: any) {
        if (val == 1) {
            this.form.mnp_wechat_auth = 1
        }
    }
    @Watch('form.toutiao_auth')
    mnpToutiaoAuthWatch(val: any) {
        if (val == 0) {
            this.form.toutiao_auto_auth = 0
        }
    }
    @Watch('form.mnp_auto_wechat_auth')
    mnpAutoToutiaoAuthWatch(val: any) {
        if (val == 1) {
            this.form.toutiao_auth = 1
        }
    }

    // 表单校验
    rules = {
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit (formName: string) {
      // 验证表单格式是否正确
      const refs = this.$refs[formName] as HTMLFormElement
      refs.validate((valid: boolean): any => {
        if (!valid) return
        this.handleGameConfigEdit()
      })
    }

    // 提交配置
    async handleGameConfigEdit (): Promise<void> {
      await apiGameConfigChange({ ...this.form, aid: this.identity })
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 获取详情
    async getGameConfigDetailFunc (): Promise<void> {
      const res: any = await apiGameConfigDetail({
        aid: this.identity as number
      })
      this.form = res
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query

      // 编辑模式：初始化数据
      this.identity = +query.id
      if (+query.id) {
        this.getGameConfigDetailFunc()
      }
    }
    /** E Life Cycle **/
}
